/**
 * The primary source of truth for these colors is Figma.
 *
 * @src {@link https://figma.com/design/J2hlcm5TFWuXmbu7Sqi9pA/?node-id=13-10&t=l2wqus48K5WonT8b-0}
 */
export enum Colors {
  White = '#ffffff',

  Blue1 = '#e8f4fa',
  Blue2 = '#d1e1eb',
  Blue3 = '#aec4d4',
  Blue4 = '#507796',
  Blue5 = '#003057',
  Blue6 = '#002441',

  Peach1 = '#faede8',
  Peach2 = '#ffdbcd',
  Peach3 = '#f5bea9',
  Peach4 = '#ed9d7b',
  Peach5 = '#a45014',
  Peach6 = '#543b28',

  Gray1 = '#faf2eb',
  Gray2 = '#f0e5dd',
  Gray3 = '#d6c9bf',
  Gray4 = '#a89a8c',
  Gray5 = '#696158',
  Gray6 = '#3d3934',

  CoolGray1 = '#f2f4f5',
  CoolGray2 = '#e5e9eb',
  CoolGray3 = '#d2d6d9',
  CoolGray4 = '#abafb2',
  CoolGray5 = '#999da0',
  CoolGray6 = '#7e848a',
  CoolGray7 = '#596066',
  CoolGray8 = '#363e45',
  CoolGray9 = '#272b2e',

  Green1 = '#e6f2ec',
  Green2 = '#005c2e',
  Green3 = '#004724',

  Red1 = '#f9e6e8',
  Red2 = '#9e0012',
  Red3 = '#7a000e',

  Ocean = '#335979',
}

export default Colors;
