import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/styles';
import { format } from 'date-fns';
import React from 'react';

import { link } from 'src/clinicalSnapshot/commonStyles';
import ConflictingDspDataAlert from 'src/clinicalSnapshot/components/ConflictingDspDataAlert';
import DisplayData, { DisplayedData } from 'src/clinicalSnapshot/components/DisplayData';
import { DrugScreenStatusIcon } from 'src/clinicalSnapshot/components/DrugScreenStatusIcon';
import { useDrugScreeningData } from 'src/clinicalSnapshot/hooks/useDrugScreeningData';
import { SectionHeader } from 'src/nightingale/components/SectionHeader/SectionHeader';
import { getDaysAgo, getDaysUntil } from 'src/util/transformDate';

// expected format: 2024-09-12 (3 days ago)
const transformPastDateForSnapshotDisplay = (date: Date | null | undefined) => {
  if (!date) return '';
  return `${format(date, 'yyyy-MM-dd')} (${getDaysAgo(date)})`;
};

// expected format: 2024-09-18 (in 3 days)
const transformFutureDateForSnapshotDisplay = (date: Date | null | undefined) => {
  if (!date) return '';
  return `${format(date, 'yyyy-MM-dd')} (${getDaysUntil(date)})`;
};

// current period and due date data should be displayed together
const renderCurrentDsp = (
  currentPeriod: string | null | undefined,
  dueDate: Date | null | undefined,
) => {
  return currentPeriod && dueDate
    ? `${currentPeriod}, due ${transformFutureDateForSnapshotDisplay(dueDate)}`
    : '';
};

const buildLinkForTestKitOrder = (patientId: string, testType: string) => {
  if (testType === 'POCT') {
    return `/patients/${patientId}/pocTesting`;
  }

  return `/patients/${patientId}/overview#labs`;
};

/**
 * Only display mitigation if patient is at risk of regulatory pause or on regulatory pause
 */
const shouldRenderMitigation = (drugScreeningPeriodStatus: string) => {
  return (
    drugScreeningPeriodStatus === 'at risk of regulatory pause' ||
    drugScreeningPeriodStatus === 'on regulatory pause'
  );
};

interface DrugScreenDataArgs {
  patientId: string;
}

export const DrugScreenData = ({ patientId }: DrugScreenDataArgs) => {
  const { data } = useDrugScreeningData(patientId);
  const styles = useStyles();

  if (!data) return null;

  const {
    currentPeriod,
    dueDate,
    hasConflictingData,
    status,
    mitigation,
    testKitOrders = [],
  } = data;

  const drugScreenDataSchema: DisplayedData[] = [
    {
      label: 'Status',
      component: (
        <DrugScreenStatusIcon
          status={status}
          link={`/patients/${patientId}/overview#drugScreeningPlan`}
        />
      ),
    },
  ];

  // show current dsp period data at the top
  if (hasConflictingData) {
    drugScreenDataSchema.unshift({
      label: 'Current period',
      component: <ConflictingDspDataAlert patientId={patientId} />,
    });
  } else {
    drugScreenDataSchema.unshift({
      label: 'Current period',
      value: renderCurrentDsp(currentPeriod, dueDate),
    });
  }

  if (shouldRenderMitigation(status)) {
    drugScreenDataSchema.push({
      label: 'Mitigation',
      value: mitigation ?? '',
    });
  }

  const hasNoOpenOrders = testKitOrders.length === 0;
  const openOrdersLabel = 'Open Orders in Last 90 Days';
  if (hasNoOpenOrders) {
    drugScreenDataSchema.push({
      label: openOrdersLabel,
      component: (
        <div className={styles.warningMessage}>
          <WarningIcon className={styles.warningIcon} fontSize="small" /> No open orders
        </div>
      ),
    });
  } else {
    drugScreenDataSchema.push({
      label: openOrdersLabel,
      component: (
        <ul className={styles.list}>
          {testKitOrders.map(order => (
            <li className={styles.listItem} key={order.statusDate?.toString()}>
              <div>
                <span>
                  <a
                    className={styles.link}
                    href={buildLinkForTestKitOrder(patientId, order.testType)}
                  >
                    {order.testType}
                  </a>
                </span>{' '}
                - {order.status} {transformPastDateForSnapshotDisplay(order.statusDate)}
              </div>
            </li>
          ))}
        </ul>
      ),
    });
  }

  return (
    <div>
      <SectionHeader>Drug Screening</SectionHeader>
      <DisplayData schema={drugScreenDataSchema} />
    </div>
  );
};

const useStyles = makeStyles({
  link,
  openOrdersContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: 'rgb(70, 108, 138)',
  },
  warningIcon: {
    marginRight: 8,
  },
  warningMessage: {
    color: 'rgb(219, 148, 75)',
    fontWeight: 700,
    marginLeft: 12,

    display: 'flex',
    alignItems: 'center',
  },
  list: {
    marginTop: 0,
    paddingLeft: 'inherit',
  },
  listItem: {
    padding: '0 8px',
    marginBottom: '8px',
  },
  fields: {
    position: 'static',
    fontSize: 16,
    fontFamily: 'Nunito',
    fontWeight: 600,
    lineHeight: '17.4px',
    padding: '16px',
  },
});
