import { Colors } from 'src/common/ui/base/Colors';

export const link = {
  fontSize: 14,
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: '120%',
  color: Colors.Ocean,
  textDecoration: 'underline',
};
