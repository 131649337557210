import { makeStyles } from '@material-ui/core';
import React from 'react';

import { link } from 'src/clinicalSnapshot/commonStyles';
import { Problem } from 'src/clinicalSnapshot/types';
import { SectionHeader } from 'src/nightingale/components/SectionHeader/SectionHeader';

interface ProblemsDataArgs {
  patientId: string;
}

const ProblemsData = ({ patientId }: ProblemsDataArgs) => {
  const styles = useStyles();

  const mockData = {
    problems: [
      {
        title: 'Problem 1',
        flaggedNote: 'Flagged Note 1',
        link: '/link1',
      },
      {
        title: 'Problem 2',
        link: '/link2',
      },
    ],
    externalProblems: ['External Problem 1', 'External Problem 2'],
  };

  return (
    <div>
      <SectionHeader>Problems</SectionHeader>
      <div>In Progress {patientId}</div>
      <ul>
        {mockData.problems.map((problem: Problem) => {
          return (
            <li key={problem.title}>
              <a className={styles.link} href={problem.link}>
                {problem.title}
              </a>
            </li>
          );
        })}
      </ul>
      <ul>
        {mockData.externalProblems.map((externalProblem: string) => {
          return <li key={externalProblem}>{externalProblem}</li>;
        })}
      </ul>
    </div>
  );
};

export default ProblemsData;

const useStyles = makeStyles({
  link,
});
