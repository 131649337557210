import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { format } from 'date-fns';
import React from 'react';

import { link } from 'src/clinicalSnapshot/commonStyles';
import { usePatientZendeskTickets } from 'src/clinicalSnapshot/hooks/usePatientZendeskTickets';
import { SectionHeader } from 'src/nightingale/components/SectionHeader/SectionHeader';

interface ZdTicketsDataArgs {
  patientId: string;
}

// expected format: 2024-09-12 12:00 AM
const renderLastUpdated = (date: Date | null | undefined) => {
  if (!date) return '';
  return `${format(date, 'yyyy-MM-dd HH:mm a')}`;
};

const ZdTicketsData = ({ patientId }: ZdTicketsDataArgs) => {
  const { data: zdTaskTickets, loading } = usePatientZendeskTickets(patientId);
  const styles = useStyles();

  if (loading) {
    return <></>;
  }

  if (!zdTaskTickets || !zdTaskTickets?.length) {
    return (
      <div>
        <SectionHeader>Zendesk Task Tickets</SectionHeader>
        <div>No tickets found.</div>
      </div>
    );
  }

  return (
    <div>
      <SectionHeader>Zendesk Task Tickets</SectionHeader>
      <table className={styles.section}>
        <thead>
          <tr>
            <th className={classNames([styles.subjectColumn, styles.column])}>Subject</th>
            <th className={styles.column}>Status</th>
            <th className={styles.column}>Last Updated</th>
            <th className={styles.column}>Assignee</th>
          </tr>
        </thead>
        <tbody>
          {zdTaskTickets.map(ticket => (
            <tr className={styles.row} key={ticket.lastUpdated.toString()}>
              <td className={classNames([styles.value, styles.subjectColumn, styles.truncate])}>
                <a
                  className={styles.link}
                  href={ticket.ticketLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {ticket.subject}
                </a>
              </td>
              <td className={styles.value}>{ticket.status}</td>
              <td className={styles.value}>{renderLastUpdated(ticket.lastUpdated)}</td>
              <td className={styles.value}>{ticket.assignee}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ZdTicketsData;

const useStyles = makeStyles({
  section: {},
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  subjectColumn: {
    maxWidth: '220px',
  },
  column: {
    textAlign: 'left',
    fontSize: '12px',
  },
  row: {
    '&:nth-child(odd)': {
      backgroundColor: '#f9f9f9',
    },
  },
  value: {
    padding: '8px',
  },
  link,
});
